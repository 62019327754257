import React from "react";

import Layout from "components/layout";
import SEO from "components/seo";
import RichText from "components/rich-text";
import Box from "components/box";

import "./style.css";

// const sortByDate = (a, b) => {
//   return new Date(b.node.dateCreated) - new Date(a.node.dateCreated);
// };

const description = `
# NOW
- Working on [Pullfrog 2BITS](https://amano.games)
- [amano.games](https://amano.games/devlog) Devlog
- [indi-es.com](https://indi-es.com) Weekly newsletter
- Learning new things to cook.
- Trying to write down [knowledge](/wiki)
- Living at [Tepoztlán](https://en.wikipedia.org/wiki/Tepoztl%C3%A1n)
- Using [Mastodon](https://merveilles.town/@mario_afk)
- Working at [Scalero](https://scalero.io/)
`;

const IndexPage = () => {
  return (
    <Layout className="now">
      <SEO title="Now" />
      <Box className="wrapper">
        <img className="now-media" src="/now.gif" />
        <div className="rich-text-wrapper">
          <RichText text={description} />
        </div>
      </Box>
    </Layout>
  );
};

IndexPage.propTypes = {};

export default IndexPage;
